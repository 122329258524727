import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import { useSetState } from '@campaignhub/react-hooks'
import useCurrentUserV2 from '@hooks/useCurrentUserV2'

import Client from '.'
import Campaign from './packs/Campaign'
import BookingsDashboard from './packs/BookingsDashboard'
import ProofingOverview from './packs/ProofingOverview'

const defaultState = {
  showProofingTab: false,
}

const ClientRoutes = () => {
  const [state, setState] = useSetState(defaultState)
  const { showProofingTab } = state

  const { currentUserV2 } = useCurrentUserV2()

  const entities = useSelector(reduxState => reduxState.entities)
  const { clientTypes } = entities

  useEffect(() => {
    if (currentUserV2 && clientTypes){
      setState({ showProofingTab: clientTypes[currentUserV2?.clientTypeId]?.name !== 'Campaigntrack' })
    }
  }, [currentUserV2, clientTypes])

  return (
    <Router>
      <Client>
        <Routes>
          <Route path="/*" element={<BookingsDashboard showProofingTab={showProofingTab} />} />
          <Route path="/campaigns/:campaignId/edit" element={<Campaign />} />
          <Route path="/overviews/bookings" element={<BookingsDashboard showProofingTab={showProofingTab} />} />
          <Route path="/overviews/proofing/*" element={<ProofingOverview showProofingTab={showProofingTab} />} />
        </Routes>
      </Client>
    </Router>
  )
}

export default ClientRoutes
